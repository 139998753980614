import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import React from 'react';
import { useSelector } from 'react-redux';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';

type CalendlyModalProps = {
  open: boolean;
  onClose: () => void;
  onSchedule: () => void;
};

const CalendlyModal = ({ open, onClose, onSchedule }: CalendlyModalProps) => {
  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  useCalendlyEventListener({
    onEventScheduled: (event) => {
      analytics.track('Schedule Consultation Confirmed', event);
      onSchedule();
    },
    onDateAndTimeSelected() {
      analytics.track('Schedule Consultation Date Selected');
    },
  });

  return (
    <PopupModal
      url="https://calendly.com/d/5b9-sbw-vj5/plannery-student-loan-review"
      prefill={{
        name: `${firstName} ${lastName}`,
        email,
        smsReminderNumber: phoneNumber,
        customAnswers: {
          a1: phoneNumber,
        },
      }}
      onModalClose={onClose}
      open={open}
      /*
       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
       */
      rootElement={document.getElementById('root')!}
    />
  );
};

export default CalendlyModal;
